import Pagination from '@mui/material/Pagination';
import { styles } from '../Style';
import { useState } from 'react';
export default function CustomPagination(props) {
    const [page, setPage] = useState(1);

    const handleChange = (event,value) => {
       if (value > page) {
          props.nextPage()
          setPage(value);
       } else if (value < page) {
         props.previousPage()
         setPage(value);
       } 
   
    }
    return (<Pagination color='primary' onChange={handleChange} count={page+1} page={page} sx={[styles.pagination2]} />)
}