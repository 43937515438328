
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import { Typography } from '@mui/material';
import { styles} from '../Style';
import { ReactComponent as LogoGw } from '../../src/logo-GW-white.svg'
const MainLayout = ({ children }) => {
      return (
                    <Box sx={styles.textCenter}>
                        <NavLink to={'/'}>{<LogoGw sx={[styles.pl40,styles.pt10,styles.mb10]} height={32} />}</NavLink>
                        <Typography sx={[styles.pb100,styles.title]} variant='h4' fontWeight='bold' color='white'>Mon Espace Apporteur d'Affaires</Typography>
                        {children}
                    </Box>
      )
}
export default MainLayout;