import { SEND_DATA, GET_BROKER_DATA } from './ActionTypes';

import axios from 'axios';

export const sendData = (query) => {
    return async (dispatch) => {
        try {
            if (query === undefined || !query.brokerEmail || !query.leadFirstName  || !query.leadEmail) {
                dispatch({ type: SEND_DATA, payload: "" })
            } else {
               let response = await axios.post(`${process.env.REACT_APP_G8W_BROKER_API_URL}/registerNewLead`, query)
               await dispatch({ type: SEND_DATA, payload: await response.data })
            }
        } catch (err) {console.log(err)} 
    }
}

export const getBrokerData = (query) => {
    return async (dispatch) => {
        try {
            if (query === undefined || !query.hash ) {
                console.log("pas de query")
                dispatch({ type: GET_BROKER_DATA, payload: "" })
            } else {
                let response;
                if (query.offset == null) {
                    response = await axios.get(process.env.REACT_APP_G8W_BROKER_API_URL+"/getLeadsFromBroker/"+ query.hash)
                } else {
                    response = await axios.get(process.env.REACT_APP_G8W_BROKER_API_URL+"/getLeadsFromBroker/"+ query.hash + `?offset=${query.offset}`)
                }
                let brokerData = response.data
                await dispatch({ type: GET_BROKER_DATA, payload: brokerData })
            }
        } catch (err) {console.log(err)} 
    }
}