import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { styles } from '../Style';
export default function BrokerInfosCard(props) {
    return (
        <Card sx={styles.card}>
          <CardContent>
             { props.data.broker ?      
             <>
             <CardMedia 
                component="img"
                sx={styles.cardMedia2}
                image="/profil2.png"
                alt="green iguana"
              />
              <Typography color='primary' fontWeight='bold' gutterBottom variant="h6">Vos Informations</Typography>
              <Box  sx={[styles.row,styles.around]}>
              <Typography color='primary' variant='h6' gutterBottom>{props.data.broker.lastName}</Typography>
              <Typography color='primary' variant='h6' gutterBottom>{props.data.broker.firstName}</Typography>
              </Box>
              <Typography color='primary' gutterBottom>{props.data.broker.email}</Typography>
              <Button size="small"><AddIcon />Ajouter un profil</Button>
            </> 
            : "" }
          </CardContent>
        </Card>
    );
}