import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { styles } from '../Style';

export default function Profit(props) {
    return (
        <Card sx={[styles.profitCard]}>
        <CardContent>
           { props.data.broker ?      
           <>
           <CardMedia 
              component="img"
              sx={styles.cardMedia2}
              image="/profit.png"
              alt="green iguana"
            />
            <Typography color='primary' fontWeight='bold' gutterBottom variant="h6">Vos Gains</Typography>
            <Box sx={[styles.row,styles.center]}>
                 <Box>
                        {props.data.leads.map((leads,index) => {
                        return (
                            <Box sx={styles.row}>
                                <Typography sx={styles.marginRight} color='primary' variant='h6' gutterBottom>{props.data.leads[index].firstName}:</Typography>
                                <Typography sx={styles.marginLeft} color='primary' variant='h6' gutterBottom>{300+index} €</Typography>    
                            </Box>
                        )
                        })}
                 </Box>
            </Box>
            <Typography color='primary' gutterBottom></Typography>
          </> 
          : "" }
        </CardContent>
      </Card>
    )
}