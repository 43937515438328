import Form from '../organisms/Form';
import MainLayout from '../layouts/MainLayout';
import { ThemeProvider } from '@mui/material/styles';
import {  useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { theme } from '../Style';

export default function HomePage() {
    const Navigate = useNavigate()
    const redirect = useSelector(state => state.broker.data)
    useEffect(() => {
        if(redirect) Navigate(redirect)
    },[redirect,Navigate])
    return(
        <MainLayout>
            <ThemeProvider theme={theme}>
                <Form />
            </ThemeProvider>
        </MainLayout> 
    )
}