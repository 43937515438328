import * as Actions from '../actions/ActionTypes';
const initialState = { data: [] }

export function BrokerReducer( state = initialState, action ) { 
    switch ( action.type ) {
            default:
                return state;
            case Actions.SEND_DATA:
                return { data: action.payload }
            case Actions.GET_BROKER_DATA:
                return { data: action.payload}
    }
 }