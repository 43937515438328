import Box from '@mui/material/Box';
import { useState } from 'react';
import { sendData } from '../store/actions/BrokerActions';
import { useDispatch} from 'react-redux';
import  FormFields  from '../molecules/FormFields';
import Alerts from '../molecules/Alerts';
import Ad from '../atoms/Ad';
export default function Form() {
    let regexMail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    const dispatch = useDispatch();
    const onClose = () => { setStatus() }
    const [status, setStatus] = useState()
    const [check, setCheck] = useState(false);
    const [formData, setFormData] = useState({brokerFirstName: "",brokerLastName: "", brokerEmail: "", brokerPhone: "",leadFirstName:"",leadLastName:"",leadEmail: "", leadPhone: ""});
    const onChange = (event) => {setFormData({...formData, [event.target.name]: event.target.value})}
    const onCheck = (event) => {setCheck(!check)}
    const onSubmit = () => { if ( formData.brokerEmail === "" || formData.leadFirstName === ""  ||  formData.leadEmail === "" ||  check === false ){setStatus("error")
        } else {
            if (regexMail.test(formData.brokerEmail) !== true || regexMail.test(formData.leadEmail) !== true) {setStatus("email")
            } else {
            dispatch(sendData(formData))
            setFormData({brokerFirstName: "",brokerLastName:"", brokerEmail: "", brokerPhone: "",leadFirstName:"",leadLastName:"",leadEmail: "", leadPhone: ""})
            setStatus("success")
        }}}
    return(
        <Box>
            <Alerts status={status} onClose={onClose} />
            <Ad/>
            <FormFields onChange={onChange} onSubmit={onSubmit} formData={formData} onCheck={onCheck} check={check} />
        </Box>
    
    )
}