import {useParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {useEffect} from 'react';
import { getBrokerData } from '../store/actions/BrokerActions';
import MainLayout from '../layouts/MainLayout';
import BrokerInfosCard from '../atoms/BrokerInfosCard';
import LeadsListCards from '../atoms/LeadsListCards';
import Profit from '../atoms/BrokerProfitCard';
import CustomPagination from '../atoms/CustomPagination';
import {styles} from '../Style';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../Style';
export default function BrokerIdPage() {
    let { id } = useParams();
    let dispatch = useDispatch();
    useEffect(() => {
        let query = { hash: id }
        dispatch(getBrokerData(query))}, [dispatch,id])
    const brokerData = useSelector(state => state.broker.data);
    const nextPage = () => {
       let query = {hash: id, offset: brokerData.offset}
        dispatch(getBrokerData(query))
    }
    const previousPage = () => {
        let query = { hash: id  }
        dispatch(getBrokerData(query))
    }
    return (
        <MainLayout>
            <ThemeProvider theme={theme}>
                <Box sx={[styles.rowmd,styles.evenlymd]}>
                     <Box sx={[styles.center, styles.pb10]}><Profit data={brokerData} /></Box>
                     <Box sx={[styles.center, styles.pb100]}><BrokerInfosCard data={brokerData}/></Box>
                </Box>
                <Box sx={[styles.center,styles.col]}>
                    <Box>
                    <LeadsListCards data={brokerData} nextPage={nextPage} previousPage={previousPage}/>
                    </Box>
                    <Box sx={[styles.w25,styles.aic, styles.pb10]}>
                    <CustomPagination nextPage={nextPage} previousPage={previousPage} />
                    </Box>
                </Box>  
            </ThemeProvider>
        </MainLayout>
    )
}