import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {primary: {main: 'rgb(16,23,96)'},secondary: {main: 'rgb(250,16,105)'}}});

export const styles = {
    ad: { width:"80px", height:"90px", display:"flex","@media (max-width:1250px)": {width:"40px", height:"45px", display:"flex"}},
    aic: {display: "flex", justifyContent: "center", alignSelf: "center"},
    around: {display: 'flex', justifyContent:'space-around'},
    between: { display: 'flex', justifyContent:'space-between'},
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    bold: {fontWeight: 'bold'},
    button: {fontWeight:"bold", color:"white","&:hover": {color: "rgb(250,16,105)", borderColor: "white"}},
    cardMedia1: {height:80},
    cardMedia2: {paddingRight: 8,paddingLeft: 8},
    card: { minWidth: 275, maxWidth: 275},
    cardsm: { "@media (max-width:1250px)": {width:"20px", display:"flex",justifyContent:"center"}},
    center:{display: 'flex', justifyContent:'center'},
    col: { display: 'flex',flexDirection:"column" },
    checkboxLabel: {color: "green"},
    checkbox: {color: 'white','&.Mui-checked': {color: 'white'}},
    evenlymd: {"@media (min-width:1250px)": { display:"flex", justifyContent: "space-evenly"}},
    formFieldsRow: {display: "flex",flexDirection:"row", justifyContent:"center","@media (max-width:1250px)": {paddingLeft:"15px", paddingRight:"15px"}},
    fs60: {fontSize:60},
    h20:{height:20},
    hired:{color: 'rgb(255,215,0)'},
    inputLabelProps: {style: {color: 'white'}},
    icon: { fontSize: '18px',paddingLeft: '5px'},
    input:  {input: {color: "rgb(250,16,105)", fontWeight:"bold","&:focus": {color:"white"}}},
    listCards: {display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems:'center', paddingBottom: 10},
    mb10: {marginBottom: '10px'},
    mailed: { color: 'orange' },
    mt2: { mt: 2},
    margin: { marginBottom: 3, marginRight:5},
    marginRight: { marginRight: 3},
    marginLeft: {marginLeft: 5},
    modal: { position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: 400,sm:{"@media (max-width:1250px)":{ fontSize: '12px' }},p: 4,},
    mailInput: {input: {color: "rgb(250,16,105)", fontWeight:"bold","&:focus": {color:"white"}, "@media (max-width:1250px)": {fontSize: "12px"}}},
    next: {fontSize:"60px"},
    overflow: { overflow: "hidden"},
    pagination: {backgroundColor: 'white', width: 200, display:'flex', justifyContent: "center", borderRadius:"5px"},
    pagination2: {backgroundColor: 'white', display:'flex', justifyContent: "center", borderRadius:"5px"},
    pl40: {paddingLeft: '40px'},
    pt10: {paddingTop: '10px'},
    pr100: {paddingRight: '100px'},
    pl100: {paddingLeft: '100px'},
    pb10: {paddingBottom: '10px'},
    pb20: {paddingBottom: '50px'},
    pb100: {paddingBottom: '100px'},
    profitCard: { minWidth: 275, maxWidth: 275, minHeight:324, maxHeight:324},
    processing: {color: 'blue'},
    refused: {color: 'red'},
    row: { display: 'flex', flexDirection: 'row'},
    rowmd: {"@media (min-width:1250px)": { display: "flex", flexDirection: "row" }}, 
    success: {color: 'green'},
    typo: {"@media (max-width:1250px)": {fontSize: "10px"}},
    textCenter: {textAlign: 'center'},
    title: {"@media (max-width:1250px)": { fontSize: "20px"}}, 
    white: {color: 'white'},
    w25: {width: '200px'}
}