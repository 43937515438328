import {TextField, Button} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import { styles } from '../Style';
export default function FormFields(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
       <Box>
          <Box sx={styles.formFieldsRow}>  
            <Box sx={styles.pr100}>
                <Typography variant='h7' fontWeight='bold' sx={[styles.white,styles.pb10]}>Vos Informations <FolderSharedIcon color='secondary' /></Typography>
                <Box  sx={styles.pb10}><TextField InputLabelProps={styles.inputLabelProps} value={props.formData.brokerLastName}  type="text" sx={styles.input} name='brokerLastName' onChange={props.onChange} label="Nom" color="secondary" focused /></Box>
                <Box  sx={styles.pb10}><TextField InputLabelProps={styles.inputLabelProps} value={props.formData.brokerFirstName}  type="text" sx={styles.input} name='brokerFirstName' onChange={props.onChange} label="Prénom" color="secondary" focused /></Box>
                <Box  sx={styles.pb10}><TextField InputLabelProps={styles.inputLabelProps} value={props.formData.brokerEmail} required type="email" sx={styles.mailInput} name='brokerEmail' onChange={props.onChange} label="Email" color="secondary" focused /></Box>
                <Box  sx={styles.pb10}><TextField InputLabelProps={styles.inputLabelProps} value={props.formData.brokerPhone}  type="tel" sx={styles.input} name='brokerPhone' onChange={props.onChange} label="Numéro de téléphone" color="secondary" focused /></Box>
                <FormControlLabel control={<Checkbox name="checkbox" checked={props.check} onChange={props.onCheck}  required sx={styles.checkbox} />} label={<Typography color="white">J'accepte les</Typography>} /><Link color='secondary' onClick={handleOpen}>CGU</Link>
            </Box>
            <Box>
                <Typography variant='h7' fontWeight='bold' sx={[styles.white,styles.pb10]}>Informations du porté <ContactPageIcon color='secondary' /></Typography>
                <Box sx={styles.pb10}><TextField InputLabelProps={styles.inputLabelProps} value={props.formData.leadLastName}   type="text" sx={styles.input} name='leadLastName' onChange={props.onChange} label="Nom" color="secondary" focused /></Box>
                <Box sx={styles.pb10}><TextField InputLabelProps={styles.inputLabelProps} value={props.formData.leadFirstName} required  type="text" sx={styles.input} name='leadFirstName' onChange={props.onChange} label="Prénom" color="secondary" focused /></Box>
                <Box sx={styles.pb10}><TextField InputLabelProps={styles.inputLabelProps} value={props.formData.leadEmail} required  type="email" sx={styles.mailInput} name='leadEmail' onChange={props.onChange} label="Email" color="secondary" focused /></Box>
                <Box sx={styles.pb10}><TextField InputLabelProps={styles.inputLabelProps} value={props.formData.leadPhone}   type="tel" sx={styles.input} name='leadPhone' onChange={props.onChange} label="Numéro de téléphone" color="secondary" focused /></Box>
            </Box>
          </Box>
          <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={styles.modal}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Conditions générales d'utilisation
                </Typography>
                <Typography id="modal-modal-description" sx={styles.mt2}>
                    J'accepte de partger mes informations personelles et je certifie.....
                </Typography>
            </Box>
          </Modal>
            <Button onClick={props.onSubmit} sx={styles.button} color='secondary' variant="outlined">Submit <SendIcon sx={styles.icon}/></Button>
      </Box>
    )
}