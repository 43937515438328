import Alert from '@mui/material/Alert';
export default function Alerts(props) {
  return (
    <>
     { props.status === 'error' ? <Alert onClose={props.onClose} severity="error">Champ manquant détecté - veuillez remplir tous les champs !</Alert> : "" }
     { props.status === 'email' ? <Alert onClose={props.onClose} severity="error">Merci de renseigner une adresse email valide !</Alert> : "" }
     { props.status === 'phone' ? <Alert onClose={props.onClose} severity="error">Merci de renseigner un numéro de téléphone valide !</Alert> : "" }
     { props.status === 'success' ? <Alert onClose={props.onClose} severity="success">Vos coordonnées ont bien été transmises, veuillez verifiez vos Mails !</Alert> : "" }
    </>
  );
}