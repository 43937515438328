import Typography from '@mui/material/Typography';
import {styles} from '../Style';
import Avatar from '@mui/material/Avatar';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import EuroIcon from '@mui/icons-material/Euro';
export default function Ad() {
    return (
        <Box sx={styles.center}>
            <Box sx={styles.col}>
                <Box sx={styles.row}>
                    <Typography sx={styles.typo} color='secondary' fontWeight='bold'>Apporteur d'affaires</Typography>
                    <AddIcon sx={[styles.white,styles.center,styles.h20,styles.fs60]}/>
                    <Typography sx={styles.typo} color='secondary' fontWeight='bold'>Contrat signé</Typography>
                    <DragHandleIcon sx={[styles.white,styles.center,styles.h20,styles.fs60]} />
                    <Typography sx={styles.typo} color='secondary' fontWeight='bold'>300€ offerts</Typography>
                </Box>
                <Box sx={[styles.row,styles.between]}>
                    <Avatar src="/profil2.png" sx={styles.ad}/>
                    <EuroIcon sx={[styles.center,styles.white, styles.ad]}/>
                </Box>
            </Box>
        </Box>
    )
}