import {  combineReducers, applyMiddleware } from 'redux';
import { legacy_createStore as createStore} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { BrokerReducer } from './reducers/BrokerReducer';

const rootReducer = combineReducers({
    broker: BrokerReducer
});

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))