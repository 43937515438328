import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { styles } from '../Style';
export default function LeadsListCards(props) {
  const title = (status) => {
    if(status === 'CREATED') return 'Ajouté'; 
    if(status === 'MAILED') return 'Contacté';
  }
  const color = (status) => {
    if(status === 'CREATED') return styles.success;
    if(status === 'MAILED') return styles.mailed;
  }
    return (
      <Box sx={styles.listCards}>
        { props.data.leads ?
        <Box sx={[styles.rowmd]}>
           { props.data.leads.map((leads,index) => {return(
           <Card sx={[styles.card,styles.margin,styles.cardsm]} key={"card"+index}>
             <CardContent>
                <Box sx={[styles.row,styles.center]}>
                  <Typography color='primary' fontWeight='bold' gutterBottom variant="h6">Statut : </Typography>
                  <Typography fontWeight='bold'  gutterBottom variant="h6" sx={color(props.data.leads[index].status)}> {title(props.data.leads[index].status)}</Typography>
                </Box>
                <CardMedia component="img" sx={styles.cardMedia1} image="/profil.jpeg" alt="green iguana" />
                <Box sx={[styles.row,styles.around]}>    
                  <Typography color='primary' variant="h5" gutterBottom>{props.data.leads[index].lastName}</Typography> 
                  <Typography color='primary' variant="h5" gutterBottom>{props.data.leads[index].firstName}</Typography>
                </Box>
                <Typography color='primary' gutterBottom>Email : {props.data.leads[index].email}</Typography>
             </CardContent>
          </Card>
            )})}
        </Box>: "" }
    </Box>);
}